import { formatDate } from "@/utils/common";
import store from "@/store/index";
import {
  getScrapingResults,
  pluginDataIdDownloadGet,
  editPluginDataPut,
} from "@/clients/tracelight";

import FileSaver from "file-saver";

export default {
  name: "ScrapingResultDetails",
  components: {},
  data: () => ({
    resultDetails: {},
    formatDate: formatDate,
    showHtmlTooltip: false,
    showScreenshotTooltip: false,
    showCopyTooltip: false,
    showEditTooltip: false,
    showClipboard: false,
    clipboardMsg: null,
    user_comment: null,
    keywords: null,
    findings_link: null,
    editDetails: false,
  }),
  created() {
    getScrapingResults(
      {},
      {
        scrape_id: this.$route.params.resultId,
      }
    )
      .then((result) => {
        this.resultDetails = result.data.data;
        this.user_comment = result.data.data.user_comment;
        this.findings_link = result.data.data.findings_link;
        this.keywords = result.data.data.keywords;
        console.debug(result.data.data);
      })
      .catch(function (error) {
        console.error(error);
        alert(error);
        return;
      });
    store.dispatch(
      "scrapingResult/downloadImages",
      this.$route.params.resultId
    );
    store.dispatch("scrapingResult/getUsers");
  },
  methods: {
    submitDetails() {
      if (
        this.user_comment == null &&
        this.keywords == null &&
        this.findings_link == null
      ) {
        this.clipboardMsg = "Please enter text";
        this.showClipboard = true;
        return;
      }

      let detailValues = [
        ["keywords", this.keywords],
        ["user_comment", this.user_comment],
        ["findings_link", this.findings_link],
      ];

      let body = {};

      detailValues = detailValues.filter((e) => e[1] != null);
      detailValues.map((e) => (body[e[0]] = e[1]));

      editPluginDataPut(body, { scrape_id: this.$route.params.resultId })
        .then((result) => {
          this.clipboardMsg = "Details updated!";
          this.showClipboard = true;
          getScrapingResults(
            {},
            {
              scrape_id: this.$route.params.resultId,
            }
          )
            .then((result) => {
              this.resultDetails = result.data.data;
              console.debug(result.data.data);
              this.user_comment = result.data.data.user_comment;
              this.findings_link = result.data.data.findings_link;
              this.keywords = result.data.data.keywords;
              this.editDetails = false;
            })
            .catch(function (error) {
              console.error(error);
              this.user_comment = null;
              this.keywords = null;
              this.findings_link = null;
              this.editDetails = false;
              alert(error);
              return;
            });
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
          return;
        });
    },
    downloadHtml() {
      if (this.htmlFiles.length > 0) {
        for (let d = 0; d < this.htmlFiles.length; d++) {
          let eachDownload = this.htmlFiles[d];
          fetch(eachDownload, {})
            .then((response) => response.blob())
            .then((res) =>
              FileSaver.saveAs(
                res,
                `ScrapingResultId${this.$route.params.resultId}-Image${d}.html`
              )
            )
            .catch(function (error) {
              console.debug(error);
            });
        }
      }
    },
    downloadPng() {
      if (this.pngFiles.length > 0) {
        const id = this.$route.params.resultId;
        const payload = {};
        pluginDataIdDownloadGet(payload, {
          scrape_id: id,
          results_per_page: 3000,
        })
          .then((result) => {
            console.warn(result.data.data);
            if (result.data.data.error) {
              this.clipboardMsg = result.data.data.error;
              this.showClipboard = true;
            }
            if (result.data.data.download_urls) {
              for (
                let d = 0;
                d < result.data.data.download_urls.png.length;
                d++
              ) {
                let eachDownload = result.data.data.download_urls.png[d];
                fetch(eachDownload, {})
                  .then((response) => response.blob())
                  .then((res) =>
                    FileSaver.saveAs(res, `ScrapingResultId${id}-Image${d}.png`)
                  )
                  .catch(function (error) {
                    console.debug(error);
                  });
              }
            }
          })
          .catch(function (error) {
            console.error(error);
            return;
          });
      }
    },
    copyText(text, selector = null) {
      console.warn(text);
      let parentElem = document.body;
      if (selector) {
        parentElem = document.querySelector(selector);
      }
      console.warn(parentElem);
      const el = document.createElement("textarea");
      el.value = text;

      parentElem.appendChild(el);
      el.select();
      document.execCommand("copy");
      parentElem.removeChild(el);
      this.clipboardMsg = "Copied!";
      this.showClipboard = true;
    },
    copyPermalink() {
      this.copyText(
        window.location.origin +
          `/scrapingresultdetails/${this.$route.params.resultId}`
      );
    },
  },
  computed: {
    users() {
      const users = store.state.scrapingResult.users || [];
      const allUsers = users.concat([
        {
          email: "All",
        },
      ]);
      return allUsers;
    },
    htmlFiles() {
      const htmlFiles = store.state.scrapingResult.htmlFiles || [];
      return htmlFiles;
    },
    pngFiles() {
      const pngFiles = store.state.scrapingResult.pngFiles || [];
      return pngFiles;
    },
  },
};
